.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*-------------------------------------------------NAVBAR--------------------------------------------------------------------------------*/
:root {
  --primary-white: #fff;
  --primary-dark: rgb(52, 58, 64);
  --primary-green: rgb(50, 207, 50);
  --primary-purple: rebeccapurple;
  --primary-hover-purple: #9650ac;
  --primary-light-grey: #f4f4f4;
  --primary-grey: lightslategrey;
  --primary-dark-grey: #666666;
  --primary-black: #000;
  --primary-black: #000;
  --primary-transparent-black: rgb(0, 0, 0, 0.7);
}
nav {
  z-index: 2;
}

body {
  font-family: "Roboto Condensed", sans-serif !important;
}
.logo {
  width: 16.25rem;
}

/* NAV LINKS MENU */
nav a.nav-link {
  font-weight: 500;
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
}

.navbar-nav > li > a {
  margin-right: 0.075rem;
  margin-left: 0.075rem;
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid var(--primary-dark);
}

.navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
  height: 4rem !important;
}

@media (max-width: 768px) {
  .navbar-brand {
    width: 7rem;
  }
}
.navbar-nav > li > a.active,
.navbar-nav > li > a.active:focus {
  border-top: 0.1875rem solid var(--primary-purple);
}

.navbar-nav > li > a:hover {
  border-top: 0.1875rem solid var(--primary-purple);
}

/*-------------------------------------------------HEADER--------------------------------------------------------------------------------*/

.header-wrapper {
  position: relative;
  background: url("wall-and-laptop-background.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  height: 90vh;
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  z-index: 1;
}

.main-info h1 {
  color: var(--primary-purple);
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .main-info h1 {
    font-size: 1.7rem !important;
  }
  .typed-text {
    font-size: 1.3rem !important;
  }
}
/* ---------- TYPEDTEXT ---------*/

.typed-text {
  font-size: 2rem;
  color: var(--primary-white);
}

.btn-main-offer {
  border: 1px solid var(--primary-purple);
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: var(--primary-white);
  margin: 2rem;
}

.btn-main-offer:hover {
  text-decoration: none;
  background-color: var(--primary-hover-purple);
  color: var(--primary-white);
  transition: 0.2s ease-in-out;
}

.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

@media (max-width: 768px) {
  .particles-canvas {
    display: none;
  }
}

/* ------------------------ABOUT ME-----------*/

.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-img {
  width: 16rem;
  border-radius: 50%;
  padding: 0.3rem;
  border: var(--primary-purple);
}

.about-header {
  color: var(--primary-purple);
  text-transform: uppercase;
}

@media (max-width: 468px) {
  .profile-img {
    width: 10rem;
  }
}

/* ------------------------SERVICES-----------*/

.services {
  text-align: center;
  padding-bottom: 2rem;
  background: var(--primary-light-grey);
}

.services h1 {
  color: var(--primary-dark);
  text-transform: uppercase;
}

.services .circle {
  position: relative;
  margin: 0.374rem auto;
  background-color: var(--primary-purple);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.services .icon {
  font-size: 1.4rem;
  color: var(--primary-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.services .box {
  height: 16rem;
  border-bottom: 0.3125rem solid var(--primary-purple);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
}

.services .box:hover {
  border-bottom: 0.3125rem solid var(--primary-dark);
  background: var(--primary-purple);
}

.services .box:hover .icon {
  color: var(--primary-dark);
}

.services .box:hover .circle {
  background: var(--primary-white);
}
.services .box:hover p {
  color: var(--primary-white);
}
.services .box:hover h3 {
  color: var(--primary-white);
}

/* ------------------------EXPERIENCE-----------*/

.experience {
  padding: 0.7rem;
}

.experience-wrapper {
  position: relative;
  padding: 3.125rem;
  overflow: hidden;
}

.experience h1 {
  text-transform: uppercase;
  color: var(--primary-purple);
}

.experience-wrapper::before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background: var(--primary-grey);
  z-index: 1;
}

.timeline-block {
  width: calc(50% + 0.5rem);
  display: flex;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}
.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid var(--primary-dark);
  background: var(--primary-white);
  margin-top: 0.625rem;
  z-index: 3;
}

.timeline-content {
  width: 95%;
  padding: 0 0.9375rem;
  color: var(--primary-dark-grey);
}

.timeline-content h3 {
  margin-top: 0.3125rem;
  color: var(--primary-hover-purple);
  font-weight: 900;
}

@media (max-width: 768px) {
  .experience .container {
    padding-left: 0;
  }
  .experience-wrapper::before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }
  .timeline-block-right {
    float: none;
    width: 100%;
  }
  .timeline-block-left {
    float: none;
    direction: ltr;
    width: 100%;
  }
}

/* ------------------------PORTFOLIO-----------*/
.portfolio-wrapper {
  background: var(--primary-light-grey);
  padding: 3rem 0;
}
.portfolio-wrapper h1 {
  color: var(--primary-purple);
}

.portfolio-image {
  width: 15rem;
  border: 1px solid var(--primary-purple);
  padding: 0 0.5rem;
}

.portfolio-image-box {
  position: relative;
  margin: 1rem;
}
/* ---------------------OVERFLOW BOX-----------*/
.overflow {
  position: absolute;
  opacity: 0;
  background: var(--primary-black);
  width: 15rem;
  height: 6.8rem;
  top: 0;
  border: 1px solid var(--primary-green);
  cursor: pointer;
}

.portfolio-image-box:hover .overflow {
  opacity: 60%;
  transition: 0.3s ease-in-out;
}

.portfolio-icon {
  position: absolute;
  opacity: 0;
  margin-left: -8rem;
  margin-top: 2.75rem;
  color: var(--primary-green);
  font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

/*---------------------POPUPBOX---------------*/

.portfolio-image-popup-box {
  width: 45rem;
  padding: 0 0.5rem;
}

.hyper-link {
  cursor: pointer;
  color: var(--primary-purple);
}
.hyper-link:hover {
  cursor: pointer;
  color: var(--primary-green);
  text-decoration: none;
}

/*---------------------MEDIA---------------*/

@media (max-width: 768px) {
  .portfolio-image-popup-box {
    width: 100%;
  }
}
@media (max-height: 570px) {
  .popupbox-wrapper {
    height: 100% !important;
  }
  .portfolio-image-popup-box {
    width: 50%;
  }
}
@media (max-height: 280px) {
  .popupbox-wrapper {
    height: 90%;
  }
  .portfolio-image-popup-box {
    width: 30%;
  }
}
/*---------------------TESTIMONIALS---------------*/

.testimonials {
  background-image: url(testimonials-bg.jpg);
  background-size: cover;
  background-color: var(--primary-black);
  text-align: center;
  padding-bottom: 4rem;
  margin-bottom: 0;
  width: 100%;
}

.testimonials h1 {
  color: var(--primary-purple);
  padding: 3rem 0;
  text-transform: uppercase;
}

.testimonials-content {
  text-align: center;
  max-width: 53.125rem;
  margin: 0 auto 2rem auto;
  background: var(--primary-transparent-black);
}

.myCarousel h3 {
  color: var(--primary-purple);
  font-weight: 600;
  text-transform: uppercase;
}

.myCarousel p {
  color: var(--primary-grey);
}

.myCarousel {
  color: var(--primary-white);
  background: transparent;
  margin-top: -6%;
  width: 54%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  height: 17.875rem;
}

.carousel .slide img {
  width: 10rem !important;
  border-radius: 50%;
}

.carousel-root {
  margin: auto !important;
  margin-top: 3% !important;
}

.carousel .slide {
  background: transparent !important;
  height: 100% !important;
  padding-top: 2rem;
}

/*---------------------CONTACTS---------------*/
.contacts {
  background: var(--primary-dark);
  padding: 3rem 0;
}

.contacts h1 {
  color: var(--primary-hover-purple);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contacts p {
  color: var(--primary-light-grey);
  font-size: 1.2rem;
  text-transform: uppercase;
}
/*---------------------CONTACTS---------------*/
.contacts input,
.contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid var(--primary-dark-grey);
  border-radius: 0;
  color: var(--primary-hover-purple);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contacts .container {
  padding: 4rem 0;
}

.contacts input:focus,
.contacts textarea:focus {
  background: transparent;
  color: var(--primary-hover-purple);
  border-bottom: 0.125rem solid var(--primary-dark-grey);
}

.contacts textarea {
  height: 12.25rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0 0;
  width: 100%;
  background: none;
}

.contact-button-container{
  display: flex;
  justify-content: center;
  align-items: center;
}
#contactBtn {
  width: 30%;
}

.line {
  position: relative;
  width: 0;
  height: .125rem;
  background: var(--primary-hover-purple);
  display: inline-block;
  top: -.8rem;
}

input:focus+.line, textarea:focus+.line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}
textarea.form-control {
  margin: -0.3rem 0;
}
/*--------------------MEDIA----------*/

@media(max-width: 768px){
  .contacts {
    overflow-x: hidden;
  }
  .contacts .container{
    padding: 0 1rem;
  }
  .contacts p {
    padding: 0 .4rem;
  }
}
/* ----------- CONTACT FORM ERROR MESSAGES --------*/
.error-message {
  color: var(--primary-white);
  background: var(--primary-green);
  text-align: left;
}

.success-message {
  font-weight: 900;
  color: orchid;
}


/* ----------- FOOTER --------*/
.footer {
  background: var(--primary-black);
  color: var(--primary-dark-grey);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: var(--primary-dark-grey);
}

.footer a:hover {
  text-decoration: none;
  color: var(--primary-purple);
  cursor: pointer;
}

.footer p {
  margin-bottom: 0;
}